import React, {useContext, useEffect, useRef, useState} from 'react'
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import {useForm} from "react-hook-form";
import tableLang from "../../locales/tabulator.loc.json";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import ServicoContext from "../../context/ServicoContext";

import Loader from "../Loader";

import RHFSelect from "../forms/RHFSelect";
import {notify} from "../Notify";

import {startLoadingAction, stopLoadingAction} from "../util/Util";

const Classificar = (props) => {
    const {t} = useTranslation();

    const {servicos, sortVagasEscolas} = useContext(ServicoContext);

    const [loader, setLoader] = useState({show:true,msg:t('carregando')})

    const [tabulator, setTabulator] = useState(null);
    const tabulatorRef = useRef();
    const refDivTable = useRef();
    const refServicos = useRef();

    const [selectedCount, setSelectedCount] = useState(0)
    const [rowCount, setRowCount] = useState(0)

    const [optionsServicos, setOptionsServicos] = useState([])
    const [servicoMap, setServicoMap] = useState([])

    const [data, setData] = useState([]);

    const options = {
        selectableRows:true,
        height:"100%",
        data:data,
        columnHeaderVertAlign:"bottom",
        clipboard:true,
        clipboardCopyRowRange:'selected',
        clipboardCopyStyled:false,
        clipboardCopyConfig:{
            columnHeaders:false,
            columnGroups:false,
            rowGroups:false,
            formatCells:true
        },
        placeholder:t('msg_nenhum_registro'),
        columns:[],
        selectableRowsRangeMode:"click",
        keybindings:{},
        pagination: 'local',
        paginationSize: 25,
        paginationSizeSelector: [5, 10, 25, 50, 100],
        layout: "fitColumns",
        reactiveData:true,
        locale:'pt-br',
        langs:tableLang,
        addRowPos:'top',
        validationMode:"manual",
        rowFormatter: (row)=>{
            const data = row.getData();
        }
    };

    useEffect(()=>{
        const tabulatorInstance = new Tabulator(tabulatorRef.current, options);
        tabulatorInstance.on("rowSelectionChanged", (data, rows)=>{});
        tabulatorInstance.on("rowAdded", (row)=>{});
        tabulatorInstance.on("rowClick", (e, row)=>{});
        tabulatorInstance.on("cellEditing", (cell)=>{});
        tabulatorInstance.on("cellEdited", (cell)=>{});
        tabulatorInstance.on("tableBuilt", ()=>{
            tabulatorInstance.deselectRow();
            setTabulator(tabulatorInstance);
        });
        tabulatorInstance.on('rowSelectionChanged',(data, rows)=>{
            setSelectedCount(data.length);
        })
        tabulatorInstance.on('dataFiltered',(filters, rows)=>{
            setRowCount(rows.length);
        });
        tabulatorInstance.on('dataLoaded',(data)=>{
            setRowCount(data.length);
        });
        return () => {
            if (tabulatorInstance) {
                tabulatorInstance.destroy();
            }
            if (tabulator) {
                tabulator.destroy();
            }
        };
    },[])

    useEffect(()=>{
        if(tabulator && data){
            const columns = [
                {"title":t('cpf'), "field":"cpf"},
                {"title":t('nome'), "field":"nome"},
                {"title":t('matricula'), "field":"matricula"},
                {"title":t('pontos'), "field":"pontos"},
                {"title":t('escola'), "field":"escola_atual"},
                {"title":t('cargo'), "field":"cargo"},
                {"title":t('remocao'), "field":"escola_remocao"},
                {"title":t('opcao'), "field":"opcao"},
                {"title":t('chamada'), "field":"chamada"}
            ]
            tabulator.setColumns(columns);
            tabulator.setData(data);
            tabulator.setSort([{column:"nome", dir:"asc"}]);
            tabulator.redraw();
        }
    },[tabulator, data])

    const {
        register,
        control,
        getValue,
        watch,
        handleSubmit,
        reset,
        setFocus,
        setValue,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm();
    const onSubmit = (data, e) => {}

    const handleFilter = (e) => {
        let searchValue = e.target.value;
        if(!searchValue || searchValue.trim() === ''){
            tabulator.clearFilter();
            return;
        }

        tabulator.setFilter((data) => {
            return Object.keys(data).some((key) => {
                if(key.startsWith('_')) return false;
                return String(data[key]).toLowerCase().includes(searchValue.toLowerCase())
            });
        });
    };

    useEffect(()=>{
        const list = []
        const map = {}
        if(servicos){
            for(const [,servico] of servicos.entries()){
                if(servico._module_options && servico._module_options.cadastro) continue;
                if(servico._perm && servico._perm.editar){
                    map[servico._id] = servico;
                    list.push({value:servico._id, label:servico.nome})
                }
            }
            setServicoMap(map);
            setOptionsServicos(list);
            refServicos.current.setValue(list[0]);
            setLoader({show:false,msg:''})
        }
    },[servicos])

    const servicoWatch = watch("servico");
    const classificar = async (event)=>{
        const btn = event.currentTarget;
        startLoadingAction(btn);
        if(!servicoWatch){
            notify('info', t('classificar'), t('info_selecione_processo'))
            stopLoadingAction(btn);
            return;
        }
        setLoader({show:true,msg:t('classificando')})
        const rs = await sortVagasEscolas(servicoWatch.value).catch(err=>{
            console.error(err);
            stopLoadingAction(btn);
        });
        setData(rs);
        refDivTable.current.style.display = "block";
        stopLoadingAction(btn);
        setLoader({show:false,msg:''})
    }


    return (
        <>
            <Loader show={loader.show} msg={loader.msg} type="grid" />
            <div className="m-2">
                <div className="card mb-2">
                    <div className={"card-header border-bottom p-2"} style={{backgroundColor: "#f0f2f5"}}>
                        <span className="card-title font-weight-bold font-size-lg">{t('processos')}</span>
                    </div>
                    <div className="card-body p-3">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-1">
                                    <RHFSelect
                                        ref={refServicos}
                                        name="servico"
                                        placeholder={t('selecione_o', {item: t('processo')})}
                                        control={control}
                                        options={optionsServicos}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-0 mt-2">
                                    <button type="submit" className="btn btn-success btn-labeled btn-labeled-left" onClick={(e) => {
                                        classificar(e).catch(err => {
                                            console.error(err)
                                        })
                                    }}>
                                        <b><i className="icon-gear"></i></b> {t("classificar")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mb-2" ref={refDivTable}>
                    <div className={"card-header border-bottom p-2"} style={{backgroundColor: "#f0f2f5"}}>
                        <span className="card-title font-weight-bold font-size-lg">{t('classificacao')}</span>
                    </div>
                    <div className="card-body border-bottom p-1">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group form-group-xs row mb-0">
                                <div className="col-4">
                                    <div className="form-group form-group-feedback form-group-feedback-left mb-0">
                                        <input type="text" placeholder={t('filtro')} {...register("search-valor")} className="form-control" onKeyUp={handleFilter}/>
                                        <div className="form-control-feedback">
                                            <i className="icon-search4"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-8 text-right" style={{verticalAlign: "middle"}}>
                                    <span><b>{t('total')}</b>: {rowCount} | <b>{t('selecionados')}</b>: {selectedCount}</span>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div style={{position: "relative"}}>
                        <div ref={tabulatorRef}></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Classificar